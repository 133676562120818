<template>
  <!-- 支付弹窗 -->
  <el-dialog class="renewal-dialog"
             title="支付"
             :visible.sync="modal3"
             v-if="modal3"
             :before-close="closePayDialog">
    <!-- 支付方式选择，按钮显示 -->
    <!--<div class="pay-dialog-body" v-if="payBtn == true">
                <p>请选择支付方式：</p>
                <el-button type="primary" size="small" @click="handlePayWxQRcode(payInfoRow)">微信支付</el-button>
                <el-button type="primary" size="small" @click="handlePayAliQRcode(payInfoRow)">支付宝支付</el-button>
            </div>-->
    <!-- 支付方式选择完成，按钮消失 -->
    <div class="pay-dialog-body"
         v-if="payBtn == false">
      <div class="pay-title">
        使用
        <span v-if="PayType == 'wechatpay_native'"
              class="pay-for-method-1">微信</span>
        <span v-if="PayType == 'alipay_page'"
              class="pay-for-method-2">支付宝</span>
        扫一扫进行支付
      </div>
      <!-- 微信支付二维码 -->
      <div class="qrcode"
           id="qrcodeImg"></div>
      <!--<div class="order-right">
                    <div class="qrcode-wrap">
                        <div class="qrcode" id="qrcodeImg"></div>
                    </div>
                </div>-->
      <!-- 支付宝支付二维码 -->
      <iframe v-if="payForAli == true"
              :srcdoc="payAliQRcode"
              frameborder="no"
              border="0"
              marginwidth="10"
              marginheight="10"
              scrolling="no"
              width="220"
              height="220"
              style="overflow: hidden">
      </iframe>
      <!--<div>注：若二维码过期失效，请刷新页面重新进入支付！</div>-->
    </div>
    <!--<span slot="footer">
                <el-button @click="closePayDialog" size="small">返回</el-button>
            </span>-->
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: [],
  data () {
    return {
      modal3: false, // 是否打开支付弹窗
      payBtn: false, // 是否显示方式选择按钮
      payInfoRow: {}, // 该条订单数据
      payForWx: false, // 是否微信支付
      payForAli: false, // 是否支付宝支付
      timer: "", // 定时器
      payAliQRcode: "", // 支付宝codeURL
      TradeNo: "",
      TotalAmount: 0,
      OrderType: "Renew",
      ResourceType: "",
      qrCode: null,
      PayType: ""
    };
  },
  methods: {
    qrcode (url) {
      // 前端根据 URL 生成微信支付二维码
      return new QRCode("qrcodeImg", {
        width: 230,
        height: 230,
        text: url,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
    openPayDialog (row) {
      // 打开支付窗口
      console.log("pay info: " + JSON.stringify(row));
      this.TradeNo = row.TradeNo;
      this.TotalAmount = row.TotalAmount;
      this.ResourceType = row.ResourceType;
      this.PayType = row.PayType

      this.handlePayQRcode();

      this.modal3 = true;
      this.payBtn = false; // 显示支付方式选择按钮
      this.payInfoRow = row; // 获取该条订单数据
    },
    closePayDialog () {
      // 关闭支付窗口
      this.modal3 = false;
      this.payBtn = false;
      this.payForWx = false;
      this.payForAli = false;
      this.qrCode = null;
      clearInterval(this.timer); // 清除定时器
      console.log("closePayDialog called.");
    },
    handlePayQRcode () {
      // 获取微信支付二维码
      const payload = {
        TradeNo: this.TradeNo,
        PayType: this.PayType,
        OrderType: this.OrderType,
      };
      this.$store
        .dispatch("pay/getpaymentparams", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.qrCode = this.qrcode(res.PayReq);
            this.payForWx = true;
            this.timer = setInterval(() => {
              // 通过定时器每间隔一会去请求查询微信支付状态（具体参数根据项目需要而定）
              this.handleQueryPayStatus();
            }, 1000);
          }
        });
    },
    handleQueryPayStatus () {
      // 查询微信支付状态
      if (this.ResourceType == "Path") {
        this.$store
            .dispatch("line/getpayconfirm", {
              TradeNo: this.TradeNo,
              PayType: this.PayType,
              OrderType: this.OrderType,
            })
            .then((res) => {
              if (res.RetCode == 0 && res.Status == "Paid") {
                clearInterval(this.timer);
                this.$message.success("支付成功！");
                this.closePayDialog();
                  this.$router.push({path: "/line/linelist"});
              } else if (res.RetCode !== 0) {
                clearInterval(this.timer);
              }
            });
      } else {
        this.$store
            .dispatch("pay/getpayconfirm", {
              TradeNo: this.TradeNo,
              PayType: this.PayType,
              OrderType: this.OrderType,
            })
            .then((res) => {
              if (res.RetCode == 0 && res.Status == "Paid") {
                clearInterval(this.timer);
                this.$message.success("支付成功！");
                this.closePayDialog();
                  this.$router.push({path: "/phone/phonelist"});
              } else if (res.RetCode !== 0) {
                clearInterval(this.timer);
              }
            });
      }
    },

  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="scss">
.renewal-dialog {
  ::v-deep .el-dialog {
    width: 300px;
    height: 370px;
    background: #67c23a;
    border-radius: 5px;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ff0000;
  }
  ::v-deep .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff0000;
  }
  //   .el-dialog__body {
  //     height: 320px;
  //     padding: 0 20px;
  //     overflow: hidden;
  //   }
  .pay-dialog-body {
    width: 250px;
    height: 250px;
    //margin: 10px;
    background: #fff;
    margin-left: 5px;
    //padding-bottom: 20px;

    .pay-title {
      text-align: center;
    }

    .qrcode {
      width: 230px;
      height: 230px;

      padding-left: 10px;
      padding-bottom: 10px;
      padding-right: 10px;
      //align-content: center;
      background: #fff;
    }
  }
}

.pay-for-method-1 {
  font-weight: bold;
  color: #ff0000;
}
.pay-for-method-2 {
  font-weight: bold;
  color: #409eff;
}
</style>